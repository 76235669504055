import React, { Component } from "react";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles

// Import Bootstrap and its dependencies
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Import React-Summernote and its CSS
import "react-summernote/dist/react-summernote.css";
import "react-summernote/dist/react-summernote.js";
import { UploadImageFile } from "../services/pages.service";

export default class RichTextEditor extends Component {
  componentDidMount() {
    // Set the initial content when the component mounts
    if (this.props.initialContent) {
      ReactSummernote.insertText(this.props.initialContent);
    }
  }
  onChange = (content) => {
    this.props.onContentChange(content);
  };

  onImageUpload = async (files) => {
    const file = files[0];
    if (file) {
      const data = new FormData();
      data.append("file", file);

      const res = await UploadImageFile(data);
      const uploadedImageUrl = res.data.Location;
      console.log("uploadedImageUrl", uploadedImageUrl);

      ReactSummernote.insertImage(uploadedImageUrl);
    }
  };
  render() {
    return (
      <ReactSummernote
        value={this.props.initialContent}
        options={{
          disableDragAndDrop: true,
          height: 200,
          toolbar: [
            ["style", ["style"]],
            ["font", ["bold", "underline", "clear"]],
            ["fontname", ["fontname"]],
            ["para", ["ul", "ol", "paragraph"]],
            ["table", ["table"]],
            ["insert", ["link", "picture", "video"]],
            ["view", ["fullscreen", "codeview"]],
          ],
        }}
        onChange={this.onChange}
        onImageUpload={this.onImageUpload}
      />
    );
  }
}
